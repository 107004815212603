<template>
  <div class="rounded mb-3" v-background-3>
    <div class="p-3" :class="qwarkBorder">
      <h6 class="m-0">Formations</h6>
      <span class="text-muted text-nowrap"><small> MOOC du groupe ARKADIA </small></span>
    </div>
    <div class="d-flex align-items-center pr-3" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div class="mb-3" v-if="!loading">
      <div v-for="frm in formations" :key="frm.id">
        <router-link
          :to="{ name: 'FormationManager_frm', params: { id: frm.id } }"
          class="d-flex align-items-center hover-item w-100 p-2 pl-3 pr-3 cursor-pointer text-nowrap"
          :class="[hoverClass, qwarkBorder]"
          :active-class="activeClass"
          tag="div"
        >
          {{ frm.description }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/bases/Loader.vue";
export default {
  props: ["formations"],
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  computed: {
    activeClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "active-item-light text-danger";
      } else {
        return "active-item-dark text-warning";
      }
    },
    hoverClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
    qwarkBorder: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style>
.hover-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.active-item-light {
  background-color: rgba(0, 0, 0, 0.05);
}
.active-item-dark {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>

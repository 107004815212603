<template>
  <ContainerPage>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <FormationsManagerLeftBar v-if="!loading" :formations="formations"></FormationsManagerLeftBar>
    <div class="w-100" v-if="!loading">
      <router-view></router-view>
    </div>
  </ContainerPage>
</template>
<script>
import ContainerPage from "@/components/containers/ContainerPage.vue";
import FormationsManagerLeftBar from "@/components/profil/FormationsManagerLeftBar.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  components: {
    ContainerPage,
    FormationsManagerLeftBar,
    Loader,
  },
  data() {
    return {
      formations: [],
      loading: false,
      feedback: {},
    };
  },
  computed: {},
  methods: {
    getFormations: function () {
      this.loading = true;
      this.$http
        .get("/mooc-formations")
        .then((response) => (this.formations = response.data))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getFormations();
  },
};
</script>
<style></style>
